<template>

  <main>
    <div class="container">
      <div class="row">
        <div class="col s12">


          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/ramowka" title="Ramówka" class="breadcrumb">
            Ramówka
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Ramówka</h1>
        </div>
      </div>

      <div class="menu-inside">{{ menuTu }}</div>

      <div class="row mb-0">
        <div class="col s12">

          <div class="roadmap-week-select hide-on-large-only hide-on-extra-large-only"

               :class="{ 'roadmap-week-select-open': openWeek }"
          >
            <div class="roadmap-week-select__head"
                 @click="openWeek = !openWeek">
              {{ weekdays[date.getDay()] }}
            </div>
            <div class="roadmap-week-select__body">

              <div class="roadmap-week-select__day"
                   :data-day="weekdays[date_one.getDay()]"
                   :data-date="date_one"
                   v-for="date_one in date_arr"
                   v-on:click="dayclick(date_one)"
              >
                {{ weekdays[date_one.getDay()] }}
              </div>

            </div>
          </div>
          <div class="roadmap-week hide-on-med-and-down">


            <div class="roadmap-day" :class="{ 'roadmap-day-selected': (date.getDay() === date_one.getDay())  }"
                 :data-day="weekdays[date_one.getDay()]"
                 :data-date="date_one"
                 v-for="date_one in date_arr"
                 v-on:click="dayclick(date_one)"
            >
              {{ weekdays[date_one.getDay()] }}
            </div>

          </div>
        </div>
      </div>

      <div class="row" :class="{loading: stateCompare('loading')}">
        <div class="col s12" v-for="roadmap in roadmaps">

          <div class="roadmap" v-bind:class="{ roadmap_full: !roadmap.obj }">
            <router-link class="roadmap__thumb" v-if="roadmap.obj" :style="{ backgroundImage: `url(${roadmap.thumb})` }"
                         tag="a"
                         :to="'/audycje/'+roadmap.obj.slug"
                         :href="roadmap.obj.url">
            </router-link>

            <div class="roadmap__info">
              <router-link class="roadmap__name" v-if="roadmap.obj" tag="a"
                           :to="'/audycje/'+roadmap.obj.slug">
                {{ roadmap.obj.name }}
              </router-link>
              <span class="roadmap__name" v-if="!roadmap.obj">
                {{ roadmap.name }}
            </span>

              <div class="roadmap-part" v-for="roadmap_pos in roadmap.poss">
                <div class="roadmap-part-thumb"
                     style="background-image: url('http://nrk.strefaportali.pl/data/article/family/36ad7069a1c29f96e480f43b604f1b9b3e142b58dcbfc0ea883bd118790d6bedwide705x396.jpg');">
                </div>
                <span class="roadmap-part-name" style="width: 400px; max-width: 100%; display:inline-block;margin-top: 6px;">
                  {{ roadmap_pos.name }}
                </span>
                <b class="roadmap-part-hour">
                  {{ roadmap_pos.start }} -
                  {{ roadmap_pos.end }}
                </b>
              </div>
              <div class="clearfix"></div>
              <div class="roadmap__users" v-for="user in roadmap.users">
                <router-link :to="'/'+user.url_full" class="user-item user-item-min">
                  <div class="user-item__thumb"
                       :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
                  <div class="user-item__name">
                    {{ user.firstname }} {{ user.lastname }}
                  </div>

                </router-link>
              </div>
            </div>
            <span class="roadmap__hour">
              {{ roadmap.start }}
              -
              {{ roadmap.end }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </main>
  <SectionInfo code="roadmap"/>
</template>

<script>
import CoreArray from "@/helpers/array.js";
import axios from "@/axios2";
import ComponentListStateMachine from "../Extends/ComponentListStateMachine";
import SectionInfo from "./Home/SectionInfo";

export default {
  name: "Roadmap",
  extends: ComponentListStateMachine,
  components: {SectionInfo},
  data() {
    return {
      roadmaps: null,
      openWeek: false,
      date: new Date(),
      date_arr: null,
      menuTu: null,
      weekdays: {
        0: 'Niedziela',
        1: 'Poniedziałek',
        2: 'Wtorek',
        3: 'Środa',
        4: 'Czwartek',
        5: 'Piątek',
        6: 'Sobota',
      }
    }
  },
  methods: {
    monday: function (fromDate) {

      // length of one day i milliseconds
      var dayLength = 24 * 60 * 60 * 1000;

      // Get the current date (without time)
      var currentDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());

      // Get the current date's millisecond for this week
      var currentWeekDayMillisecond = ((currentDate.getDay()) * dayLength);

      // subtract the current date with the current date's millisecond for this week
      var monday = new Date(currentDate.getTime() - currentWeekDayMillisecond + dayLength);

      if (monday > currentDate) {
        // It is sunday, so we need to go back further
        monday = new Date(monday.getTime() - (dayLength * 7));
      }

      return monday;


    },
    dayclick: function (date) {

      this.date = date;
      this.openWeek = false;

      this.update(date);

    },
    update: function (date) {

      date = date ? date : this.date;

      const offset = date.getTimezoneOffset()
      date = new Date(date.getTime() - (offset*60*1000))
      date = date.toISOString().split('T')[0]

      let config = {
        params: {
          date: date
        }
      }

      let from = "https://off.radiokrakow.pl/api/";
      let to = "https://off.radiokrakow.pl/";


      this.stateUpdate('sendInfo');

      axios
          .get('articleroadmap/dayarr', config)
          .then(response => {

            this.roadmaps = response.data.roadmap;

            // this.roadmaps = CoreArray.changeDomain(this.roadmaps, 'thumb', from, to);

            this.roadmaps.map(function (val) {
              // val.users = CoreArray.changeDomain(val.users, 'avatar_url', from, to);
              val.users = CoreArray.changeDomain(val.users, 'url_full', 'ludzie-radia', 'zespol');

            })

            this.stateUpdate('sendSuccess');

          })
          .catch(error => {

            this.stateUpdate('sendWrong');
            this.refresh();

          });
    }
  },
  created() {

    let monday = this.monday(this.date);

    this.date_arr = [];
    for (let i = 0; i < 7; i++) {

      let result = new Date(monday);
      result.setDate(result.getDate() + i );

      this.date_arr.push(result)
    }


    this.update();

    // this.menuTu = '<MenuBottom/>';
    // this.refresh();
  },
  watch: {
    date: function (val) {
    },
  }

}
</script>

<style scoped>

.roadmap-week {
  width: 100%;
  margin-bottom: 24px;
  float: left;
}

.roadmap-week .roadmap-day:hover {

}

.roadmap-week .roadmap-day {
  width: calc(100% / 7);
  height: 50px;
  line-height: 50px;
  display: inline-block;
  margin: 0;
  padding: 0;
  color: #000;
  font-weight: bold;
  float: left;

  text-align: center;


  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -moz-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;

  cursor: pointer;


}

.roadmap-week .roadmap-day-selected {

  background-color: #1d4bfe;
  color: #fff;
  /*box-shadow: 0 0 14px 0 rgba(255, 255, 255, 0.25);*/
  /*transform: scale(1.1);*/
}

/*.roadmap-week .roadmap-day:nth-child(1),*/
/*.roadmap-week .roadmap-day:nth-child(4) {*/
/*    background-color: #ffffff22;*/
/*}*/

/*.roadmap-week .roadmap-day:nth-child(2),*/
/*.roadmap-week .roadmap-day:nth-child(5) {*/
/*    background-color: #ffffff44;*/
/*}*/

/*.roadmap-week .roadmap-day:nth-child(3),*/
/*.roadmap-week .roadmap-day:nth-child(6) {*/
/*    background-color: #ffffff66;*/
/*}*/

/*.roadmap-week .roadmap-day:nth-child(7) {*/
/*    background-color: #ffffff88;*/
/*}*/

@media screen and (max-width: 1024px) {


  .roadmap-week .roadmap-day {
    width: 100%;

    margin-bottom: 12px;
  }

}

.roadmap {

  border: 1px solid #eee;
  background-color: #fff;

  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
  padding: 24px 24px 24px 200px;

  margin-bottom: 24px;
  position: relative;

  min-height: 116px;
}

.roadmap.roadmap_full {
  padding: 24px !important;
}

.roadmap__thumb {
  left: 0;
  top: 0;
  bottom: 0;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  position: absolute;

  height: 116px;
  width: 180px;
}

.roadmap__hour {

  position: absolute;
  left: auto;
  top: 50%;
  transform: translateY(-50%);
  color: #777;
  font-weight: 700;
  right: 24px;

}

.roadmap__name {
  font-size: 24px;
  font-weight: 700;
  color: #000;

}

.roadmap__info {
  top: 50%;
  transform: translateY(-50%);

  position: absolute;
}

.roadmap__users {
  margin-top: 6px;
    margin-right: 6px;
  display: inline-block;
  color: #e8e8e8;
}

.roadmap__users a {
  color: #777;
}

@media screen and (max-width: 768px) {


  .roadmap {
    padding: 12px;
    min-height: auto;
  }

  .roadmap__hour {
    margin-top: 24px;
    display: inline-block;
    width: 100%;
    margin-bottom: -10px;
  }

  .roadmap__thumb {
    position: initial;
    width: 100%;
    height: 0;
    padding-bottom: 67%;

    display: block;

    margin-bottom: 12px;


  }

  .roadmap__info {
    position: initial;

    transform: none;
  }

  .roadmap__hour {
    position: initial;

  }


  .roadmap-week-select {
    margin-bottom: 12px;

    z-index: 20;
    position: relative;
  }

  .roadmap-week-select__head {

    cursor: pointer;
    font-size: 1.3rem;
    text-transform: uppercase;
    padding: 24px 20px;
    position: relative;

    color: #fff;
    background-color: #1d4bfe;
  }

  .roadmap-week-select__head:before {
    position: absolute;
    content: '';
    top: 32px;
    right: 32px;
    width: 18px;
    height: 18px;
    border-left: 2px solid #fff;
    border-top: 2px solid #fff;
    transition: 0.5s;
    will-change: transform;
    transform: rotate(
        -135deg
    ) translateY(6px) translateX(6px);
  }

  .roadmap-week-select.roadmap-week-select-open .roadmap-week-select__head:before {

    transform: rotate(
        45deg
    );
  }

  .roadmap-week-select__body {
    color: #000;
    background-color: #fff;

    position: absolute;

    width: calc(100%);

    display: none;


  }

  .roadmap-week-select__day {
    color: #000;
    background-color: #fff;

    padding: 24px 20px;
  }

  .roadmap-week-select.roadmap-week-select-open
  .roadmap-week-select__body {

    display: block;
  }


}
</style>